import React, { useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Header, EmptyInfo } from 'components/titles/v2';
import { Flex, Container } from 'components/grids/v3';

import { List } from './list/List';
import { generate_file_v2 } from '../utils/excel';
import { DateSelector } from '../utils/DateSelector';

const MaintenanceHistory = ({ items, totals }) => {
  const header = [
    'NRO°',
    'FECHA',
    'CAPITAL',
    'INTERES',
    'CUOTA',
    'SALDO',
  ];

  const parse_data = () => {
    const rows = items.map((item) => ({
      count: item.count,
      date: moment(item.date).format('MM/DD/YYYY'),
      capital: item.capital.toFixed(2),
      interest: item.interest.toFixed(2),
      insurance: item.insurance.toFixed(2),
      monthly_fee: item.monthly_fee.toFixed(2),
      missing_amount: item.missing_amount.toFixed(2),
    }));
    rows.push({
      count: '',
      date: '',
      capital: totals.loan.toFixed(2),
      interest: totals.interest.toFixed(2),
      insurance: totals.insurance.toFixed(2),
      monthly_fee: totals.total.toFixed(2),
      missing_amount: '',
    });

    return rows;
  };

  const on_download = () => {
    generate_file_v2(header, parse_data(), 'mantenimiento', 'mantenimiento');
  };

  const on_search = useCallback((range) => {
    // eslint-disable-next-line
    console.log(range);
  }, []);

  return (
    <Flex direction={Flex.V}>
      <Container width="1200px">
        <Header title="Plan de pagos" />
        <DateSelector on_search={on_search} on_download={on_download} />
        <Flex.Item>
          {(items.length === 0) && <EmptyInfo description="No existe visitantes en el hotel" />}
          {(items.length > 0) && <List items={items} on_select={() => {}} />}
          <Flex direction={Flex.V} class_name="report-history-list">
            <Flex.Scrap class_name="report-list-item">
              <Flex direction={Flex.H}>
                <Flex.Item flex={1}>
                  {}
                </Flex.Item>
                <Flex.Item flex={2}>
                  {}
                </Flex.Item>
                <Flex.Item flex={2}>
                  {`Capital ${totals.loan.toFixed(2)} Bs.`}
                </Flex.Item>
                <Flex.Item flex={2}>
                  {`Interes ${totals.interest.toFixed(2)} Bs.`}
                </Flex.Item>
                <Flex.Item flex={2}>
                  {`Seguro ${totals.insurance.toFixed(2)} Bs.`}
                </Flex.Item>
                <Flex.Item flex={2}>
                  {`Total ${totals.total.toFixed(2)} Bs.`}
                </Flex.Item>
                <Flex.Item flex={2}>
                  {}
                </Flex.Item>
              </Flex>
            </Flex.Scrap>
          </Flex>
        </Flex.Item>
        <br />
      </Container>
    </Flex>
  );
};

MaintenanceHistory.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  totals: PropTypes.any.isRequired,
};

export { MaintenanceHistory };
