import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { IButton } from 'components/buttons/v2';
import { Flex } from 'components/grids/v3';

const DateSelector = ({ on_search, on_download }) => {
  useEffect(() => {
    on_search();
  }, [on_search]);

  return (
    <>
      <Flex.End>
        <IButton label="Descargar" icon="clock outline" on_click={on_download} />
      </Flex.End>
    </>
  );
};

DateSelector.propTypes = {
  on_search: PropTypes.func.isRequired,
  on_download: PropTypes.func.isRequired,
};

export { DateSelector };
