import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Flex } from 'components/grids/v3';

const ListItem = ({ item, on_select }) => {
  const on_click = (e) => {
    e.stopPropagation();
    on_select(item);
  };

  return (
    <Flex.Scrap class_name="report-list-item" onClick={on_click}>
      <Flex direction={Flex.H}>
        <Flex.Item flex={1}>
          {item.count}
        </Flex.Item>
        <Flex.Item flex={2}>
          {`${moment(item.date).format('MM/DD/YYYY')}`}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.capital.toFixed(2)}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.interest.toFixed(2)}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.insurance.toFixed(2)}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.monthly_fee.toFixed(2)}
        </Flex.Item>
        <Flex.Item flex={2}>
          {item.missing_amount.toFixed(2)}
        </Flex.Item>
      </Flex>
    </Flex.Scrap>
  );
};

ListItem.propTypes = {
  item: PropTypes.any.isRequired,
  on_select: PropTypes.func.isRequired,
};

export { ListItem };
