import React from 'react';
import PropTypes from 'prop-types';

import './Screen.scss';

const Screen = ({ children }) => {
  const style = {
    // linear-gradient(0deg, rgb(221, 103, 103) 0%, rgb(232, 62, 86) 35%, rgb(39, 31, 31) 100%)
    // linear-gradient(45deg, rgb(38, 25, 25) 0%, rgb(199, 168, 255) 51%, rgb(214, 192, 255) 100%)
    // linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(245, 152, 255) 51%, rgb(0, 0, 0) 100%)
    // background: 'linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(65, 69, 99) 51%, rgb(0, 0, 0) 100%)'
    // 'linear-gradient(45deg, rgb(60 58 68) 0%, rgb(27 27 48) 67%, rgb(237 28 147) 100%)'
    // 'linear-gradient(36deg, rgb(170 134 82) 0%, rgb(4 18 29) 65%, rgb(65 166 187) 100%)',
    // 'linear-gradient(36deg, rgb(45 45 45) 0%, rgb(29 108 100) 59%, rgb(69 165 185) 100%)',
    // 'linear-gradient(36deg, rgb(45 45 45) 0%, rgb(41 124 145) 59%, rgb(69 165 185) 100%)',
    // 'linear-gradient(36deg, rgb(5 67 85) 0%, rgb(5 131 167) 59%, rgb(0 193 233) 100%)',
    // 'linear-gradient(36deg, rgb(21 48 56) 0%, rgb(10 44 54) 50%, rgb(69 155 172) 100%)',
    // linear-gradient(0deg, rgb(6 38 72) 0%, rgb(11 72 114) 100%);
    // 'linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(51 26 52) 51%, rgb(0, 0, 0) 100%)',
    // 'linear-gradient(45deg, rgb(52, 52, 52) 0%, rgb(20, 20, 20) 51%, rgb(20, 20, 20) 100%)',
    // 'linear-gradient(45deg, rgb(45, 101, 135) 0%, rgb(16, 42, 58) 22%, rgb(7, 28, 40) 100%)',
    // 'linear-gradient(45deg, rgb(58, 64, 68) 0%, rgb(17, 30, 39) 36%, rgb(3, 26, 39) 100%)',
    background: 'linear-gradient(0deg, rgb(14, 13, 21) 0%, rgb(122, 65, 65) 50%, rgb(38, 35, 58) 100%)',
  };

  return (
    <div className="screen" style={style}>
      {children}
    </div>
  );
};

Screen.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Screen };
