import React, { useReducer } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';

import actions from 'actions/globals/v1';
import api from 'generic/api/globals/v1';
import { GENERIC } from 'consts';
import { form } from 'generic/functions/globals';
import { toast } from 'generic/services/globals';
import { Header as Title } from 'components/titles/v2';
import { Container } from 'components/grids/v3';

import { Screen, IButton, HContent, VContainer, VContent, CContainer } from 'components';

import { Header } from '../header/Header';

import './Login.scss';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [item, set_item] = useReducer(form.reducer, {});

  const on_submit = (e) => {
    e.preventDefault();
    api.auth.login(item)
    .then(({ data }) => {
      dispatch(actions.auth.login(data));
      history.push(`${GENERIC.APP_BASE_URL}/main`);
    })
    .catch(toast.api_danger);
  };

  const on_cancel = () => {
    history.push('/');
  };

  const on_change = (event) => {
    set_item({
      name: event.target.name,
      value: event.target.value,
    });
  };

  const login_form = () => (
    <Form inverted onSubmit={on_submit}>
      <Form.Field>
        <label>Email</label>
        <input name="email" type="text" placeholder="example@example.com" onChange={on_change} />
      </Form.Field>
      <Form.Field>
        <label>Password</label>
        <input name="password" type="password" placeholder="****" onChange={on_change} />
      </Form.Field>

      <IButton label="Cancel" icon="remove" onClick={on_cancel} />
      {/* <IButton label="Iniciar session" icon="checkmark" submit /> */}
    </Form>
  );

  return (
    <Screen>
      <HContent>
        <VContainer>
          <Header />
          <VContent>
            <CContainer>
              <Container width="25rem" height="auto">
                <Title title="Iniciar session" />
                {login_form()}
              </Container>
            </CContainer>
          </VContent>
        </VContainer>
      </HContent>
    </Screen>
  );
};

export { Login };
