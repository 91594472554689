const STORE_VERSION = 'calculator_v3';

const INITIAL_ITEM = {
  bs_loan_amount: 69600,
  sus_loan_amount: 10000,
  yearly_loan_time: 5,
  monthly_loan_time: 60,
  yearly_rate_interest: 13,
  monthly_rate_interest: 1.08,
  sus: 6.96,
  insurance: 0.05,
  salary: 4640,
};

export { STORE_VERSION, INITIAL_ITEM };
