import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

import { StorageService } from 'generic/services/globals';
import { useFormReducer } from 'generic/functions/globals/reducer';
import { Flex, Container } from 'components/grids/v3';
import { Screen } from 'components';
import { IButton } from 'components/buttons/v2';

import { STORE_VERSION, INITIAL_ITEM } from './contants';
import { quota, calc_payment_plan, round, total_amount, print_total_calc } from './utils/calculator';
import { MaintenanceHistory } from './maintenance/MaintenanceHistory';

import { Header } from '../header/Header';

import './Calculator.scss';

const config = StorageService.load(STORE_VERSION);

const Calculator = () => {
  const { item, set_item } = useFormReducer(config || INITIAL_ITEM);
  const [items, set_items] = useState([]);
  const [totals, set_totals] = useState({
    loan: 0,
    interest: 0,
    insurance: 0,
    total: 0,
  });

  const on_bs_amount_change = (event) => {
    const amount = parseFloat(event.target.value, 10);
    set_item({
      ...item,
      bs_loan_amount: amount,
      sus_loan_amount: round(amount / item.sus),
      salary: round((amount / item.monthly_loan_time) * 4),
    });
  };

  const on_sus_amount_change = (event) => {
    const amount = parseFloat(event.target.value, 10);
    set_item({
      ...item,
      bs_loan_amount: round(amount * item.sus),
      sus_loan_amount: amount,
      salary: round(((amount * item.sus) / item.monthly_loan_time) * 4),
    });
  };

  const on_yearly_rate_change = (event) => {
    const amount = parseFloat(event.target.value, 10);
    set_item({
      ...item,
      yearly_rate_interest: amount,
      monthly_rate_interest: round(amount / 12),
    });
  };

  const on_monthly_rate_change = (event) => {
    const amount = parseFloat(event.target.value, 10);
    set_item({
      ...item,
      yearly_rate_interest: round(amount * 12),
      monthly_rate_interest: amount,
    });
  };

  const on_yearly_loan_change = (event) => {
    const amount = parseFloat(event.target.value, 10);
    set_item({
      ...item,
      yearly_loan_time: amount,
      monthly_loan_time: round(amount * 12),
    });
  };

  const on_monthly_loan_change = (event) => {
    const amount = parseFloat(event.target.value, 10);
    set_item({
      ...item,
      yearly_loan_time: round(amount / 12),
      monthly_loan_time: amount,
    });
  };

  const on_sus_change = (event) => {
    const amount = parseFloat(event.target.value, 10);
    set_item({
      ...item,
      sus: amount,
      bs_loan_amount: round(item.sus_loan_amount * amount),
    });
  };

  const on_insurance_change = (event) => {
    const amount = parseFloat(event.target.value, 10);
    set_item({
      ...item,
      insurance: amount,
    });
  };

  const on_salary_change = (event) => {
    const salary = parseInt(event.target.value, 10);
    const amount = total_amount(salary, item.yearly_loan_time);
    set_item({
      ...item,
      salary,
      bs_loan_amount: round(amount),
      sus_loan_amount: round(amount / item.sus),
    });
  };

  const on_download = () => {
    set_item(INITIAL_ITEM);
    StorageService.save(STORE_VERSION, INITIAL_ITEM, false);
  };

  useEffect(() => {
    // eslint-disable-next-line
    set_totals({
      loan: items.reduce((total, o) => total + o.capital, 0),
      interest: items.reduce((total, o) => total + o.interest, 0),
      insurance: items.reduce((total, o) => total + o.insurance, 0),
      total: items.reduce((total, o) => total + o.monthly_fee, 0),
    });
  }, [items]);

  useEffect(() => {
    // eslint-disable-next-line
    console.log('generator...');
    const monthly_fee = quota(
      item.bs_loan_amount,
      item.monthly_rate_interest + item.insurance,
      item.monthly_loan_time,
      4,
    );
    StorageService.save(STORE_VERSION, item, false);
    set_items([...calc_payment_plan(item, monthly_fee)]);
  }, [item]);

  const login_form = () => (
    <Form inverted>
      <Form.Group widths="equal">
        <Form.Field>
          <label>MONTO (Bs.)</label>
          <input name="bs_loan_amount" type="number" placeholder="70000Bs" value={item.bs_loan_amount} onChange={on_bs_amount_change} step="any" />
          <label>{`MONTO ($us., ${item.sus}bs=1$us)`}</label>
          <input name="sus_loan_amount" type="number" placeholder="10000$us" value={item.sus_loan_amount} onChange={on_sus_amount_change} step="any" />
        </Form.Field>
        <Form.Field>
          <label>TASA DE INTERES (Anual)</label>
          <input name="yearly_rate_interest" type="number" placeholder="13%" value={item.yearly_rate_interest} onChange={on_yearly_rate_change} step="any" />
          <label>TASA DE INTERES (Mesual)</label>
          <input name="monthly_rate_interest" type="number" placeholder="13% / 12" value={item.monthly_rate_interest} onChange={on_monthly_rate_change} step=".01" />
        </Form.Field>
        <Form.Field>
          <label>PLAZO DEL PRESTAMO (Años)</label>
          <input name="yearly_loan_time" type="number" placeholder="5 Años" value={item.yearly_loan_time} onChange={on_yearly_loan_change} />
          <label>PLAZO DEL PRESTAMO (Meses)</label>
          <input name="monthly_loan_time" type="number" placeholder="60 Meses" value={item.monthly_loan_time} onChange={on_monthly_loan_change} />
        </Form.Field>
        <Form.Field>
          <label>PRECIO DEL DOLAR</label>
          <input name="sus" type="number" placeholder="6.96Bs" value={item.sus} onChange={on_sus_change} step=".01" />
          <label>SEGURO DE DESGRAVAMEN</label>
          <input name="insurance" type="number" placeholder="0.005%" value={item.insurance} onChange={on_insurance_change} step=".01" />
        </Form.Field>
        <Form.Field>
          <label>SALARIO</label>
          <input name="salary" type="number" placeholder="2362Bs" value={item.salary} onChange={on_salary_change} step="100" />
          <label>DATOS DE CALCULO</label>
          <IButton label="Borrar" icon="clock outline" on_click={on_download} />
        </Form.Field>
      </Form.Group>
    </Form>
  );

  return (
    <Screen>
      <Flex direction={Flex.V}>
        <Header />
        <Flex.Item>
          <Container width="1200px" height="auto">
            {login_form()}
          </Container>
          <Container width="1200px" height="auto">
            <div>
              <ul>
                <li>
                  MONTO (bs) = El monto representa el total que un prestatario puede pagar
                  durante los años que dure el prestamo (SUELDO/4)*(AÑOS*12).
                </li>
                <li>
                  <ul>
                    <li>
                      {`SUELDO = ${item.salary}/4 = ${item.salary / 4}Bs `}
                      {`(2362Bs salario minimo nacional), por lo general se termite MAXIMO que las CUOTAS, NO superen el (${item.salary / 4}Bs) 25% de tus ingresos`}
                      <input type="number" value={item.salary} onChange={on_salary_change} />
                    </li>
                    <li>
                      AÑOS = 5, 10, 15 y 20 las cantidad mas comunes de prestamo
                    </li>
                    <li>
                      {`Ej. = (${item.salary}/4)*(AÑOS*12)`}
                    </li>
                    <li>
                      <ul>
                        <li>
                          {print_total_calc(item.salary, 5, item.sus)}
                        </li>
                        <li>
                          {print_total_calc(item.salary, 10, item.sus)}
                        </li>
                        <li>
                          {print_total_calc(item.salary, 15, item.sus)}
                        </li>
                        <li>
                          {print_total_calc(item.salary, 20, item.sus)}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  MONTO ($us) = Monto para una fasil convercios para productos de alto valor
                </li>
                <li>
                  TASA DE INTERES = Las tasas de interes mas comunes son
                  ~5% (vivienda), ~13% (consumo),
                  (las tasas de interes arriba de 15% se considerarian muy caras)
                </li>
                <li>
                  PLAZO DEL PRESTAMO = la cantidad de años mas comunes de prestamo son
                  5, 10, 15 y 20
                </li>
                <li>
                  PRECIO DEL DOLAR = el tipo de cambio se mantiene inalterable
                  en Bs 6,86 para la compra y Bs 6,96 para la venta por cada dólar
                </li>
                <li>
                  SEGURO DE DESGRAVAMEN = 0.05 y 0.09 es un seguro
                  que cubre el saldo deudor de un prestatario,
                  por muerte o incapacidad total permanente del deudor
                </li>
                <li>
                  BORRAR = La informacion se guardada
                  en el mismo equipo para mejorar futuros calculos
                </li>
              </ul>
            </div>
          </Container>
          <MaintenanceHistory items={items} totals={totals} />
        </Flex.Item>
      </Flex>
    </Screen>
  );
};

export { Calculator };
