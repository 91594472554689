import moment from 'moment';

const quota = (amount, monthly_interest, monthly_time, round) => {
  const r = monthly_interest / 100;
  return ((r * amount) / (1 - ((1 + r) ** -monthly_time))).toFixed(round);
};

const calc_payment_plan = (item, monthly_fee) => {
  const items = [];
  let count = 0;
  let start_date = moment();
  let missing_amount = item.bs_loan_amount;

  for (let i = 0; i < item.monthly_loan_time; i += 1) {
    const interest = missing_amount * (item.monthly_rate_interest / 100);
    const insurance = missing_amount * (item.insurance / 100);
    const capital = monthly_fee - interest - insurance;
    const loan_amount = missing_amount;

    missing_amount -= capital;
    count += 1;
    start_date = start_date.add(1, 'M');

    items.push({
      date: start_date.clone(),
      count,
      interest,
      insurance,
      capital,
      loan_amount,
      missing_amount,
      monthly_fee: interest + capital + insurance,
    });
  }
  // do {
  // } while (missing_amount.toFixed(0) > 0);

  return items;
};

const round = (value) => parseFloat((value).toFixed(2), 10);

const total_amount = (salary, year) => (salary / 4) * (year * 12);

const print_total_calc = (salary, years, dolar) => `a ${years} años ( ${round(total_amount(salary, years))}Bs | ${round(total_amount(salary, years) / dolar)}$us )`;

// var calculateInterest = function(total, years, ratePercent, roundToPlaces) {
//   var interestRate = ((ratePercent / 100) + 1);
//   return (total * Math.pow(interestRate, years)).toFixed(roundToPlaces);
// }
// var answer = calculateInterest(70000, 5, 10, 4);

export { quota, calc_payment_plan, round, total_amount, print_total_calc };
