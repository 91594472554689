import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Dimmer } from 'components/elements/v1';
import { ReactNotifications } from 'react-notifications-component';

import { Calculator } from './app/calculator/Calculator';
import { NotFound } from './app/not-found/NotFound';
import { Login } from './app/login/Login';
import { Register } from './app/register/Register';

import './App.css';

const App = () => {
  const { loading } = useSelector((state) => state.loading);

  return (
    <BrowserRouter>
      <ReactNotifications />
      <Dimmer active={loading}>
        <Switch>
          <Route exact path="/register">
            <Register />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route path="/">
            <Calculator />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </Dimmer>
    </BrowserRouter>
  );
};

export default App;
